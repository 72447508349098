html, body {
  font-family: 'Lato', sans-serif;
  font-weight: inherit;
  letter-spacing: 0px;
  color: #000;
  margin: 0px;
  padding: 0px;
  border: 0px none;
}

.row {
  position: relative;

  max-width: 480px;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    max-width: initial;
  }

  @media screen and (min-width: 1025px) {
    max-width: 720px;
  }

  @media screen and (min-width: 1400px) {
    max-width: 1170px;
  }
}
